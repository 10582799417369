
import { Component, Vue } from "vue-property-decorator";
import PickFile from "@/components/PickFile.vue"; // @ is an alias to /src
import HeaderBar from "@/components/HeaderBar.vue";
import InterlabExplorer from "@/components/InterlabExplorer.vue";
import { Interlab } from "../interlabReader";
import InterlabReader from "../interlabReader";

@Component({
  components: {
    HeaderBar,
    PickFile,
    InterlabExplorer,
  },
})
export default class DefaultView extends Vue {
  showDismissibleAlert = false;
  errorMessage = "No error";
  private exampleAdm = [
    {
      Lablittera: "DM-990908",
      Namn: "MRF",
      Adress: "PG Vejdes väg 15",
      Postnr: "351 96",
      Ort: "växjö",
      Kommunkod: "0780",
      Projekt: "Demoproj",
      Laboratorium: "SGS",
      Provtyp: "",
      Provtagare: "NSG",
      Registertyp: "DV",
      ProvplatsID: "",
      Provplatsnamn: "Demo 1 vattenverk",
      "Specifik provplats": "Utgående",
      Provtagningsorsak: "Dricksvatten enligt SLVFS 2001:30",
      Provtypspecifikation:
        "Föreskriven regelbunden undersökning enligt SLVFS 2001:30",
      Bedömning: "Nej",
      "Kemisk bedömning": "Tjänligt",
      "Mikrobiologisk bedömning": "",
      Kommentar: "",
      År: "2010",
      Provtagningsdatum: "2010-09-07",
      Provtagningstid: "10:15",
      Inlämningsdatum: "2010-09-07",
      Inlämningstid: "14:15",
    },
  ];

  private exampleDat = [
    {
      Lablittera: "DM-990908",
      Metodbeteckning: "SS-EN ISO 7887-1/4",
      Parameter: "Färgtal",
      Mätvärdetext: "",
      Mätvärdetal: "5",
      Mätvärdetalanm: "",
      Enhet: "mg/l Pt",
      Rapporteringsgräns: "",
      Detektionsgräns: "",
      Mätosäkerhet: "",
      Mätvärdespår: "",
      Parameterbedömning: "",
      Kommentar: "",
    },
    {
      Lablittera: "DM-990908",
      Metodbeteckning: "ISO 17294-2",
      Parameter: "Järn",
      Mätvärdetext: "",
      Mätvärdetal: "0,06",
      Mätvärdetalanm: "",
      Enhet: "mg/l",
      Rapporteringsgräns: "",
      Detektionsgräns: "",
      Mätosäkerhet: "",
      Mätvärdespår: "",
      Parameterbedömning: "",
      Kommentar: "",
    },
  ];

  private interlab: Interlab = {
    provdat: this.exampleDat,
    provadm: this.exampleAdm,
    version: "4.0",
    filename: "exempel.lab",
  };

  private importFileProcessActive = false;

  private datinit = "datinitval";
  private adminit = "adminitval";

  constructor() {
    super();
  }

  public importFile(val: { file: File; enc: string }) {
    console.log("input event noted in Default view: ", val);
    if (!val) {
      console.log("no file to import");
      return;
    }
    this.importFileProcessActive = true;
    this.nextTwik().then(() => {
      const ireader = new InterlabReader(this);
      ireader.onload = () => {
        this.interlab = ireader.getInterlab();
        setTimeout(() => {
          this.importFileProcessActive = false;
        }, 800);
      };
      ireader.onError = (message: string) => {
        this.errorMessage = message;
        this.showDismissibleAlert = true;
        this.interlab = {
          provdat: this.exampleDat,
          provadm: this.exampleAdm,
          version: "4.0",
          filename: "exempel.lab",
        };
        setTimeout(() => {
          this.importFileProcessActive = false;
          this.showDismissibleAlert = false;
        }, 8000);
      };

      //console.log("start reading intelrab: ", Date.now().toLocaleString());

      ireader.readAsJson(val.file, val.enc);
    });
  }

  async nextTwik() {
    return new Promise<void>((resolve) => {
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          requestAnimationFrame(() => {
            requestAnimationFrame(() => {
              resolve();
            });
          });
        });
      });
    });
  }
}
