
import { Component, Model, Prop, Vue, Watch } from "vue-property-decorator";
@Component
export default class PickFile extends Vue {
  @Model() public selectedFile: File | null = null;
  @Prop()
  public busy!: boolean;
  public encodings: Array<string> = [
    "utf-8",
    "utf-16",
    "utf-16be",
    "utf-16le",
    "cp1250",
  ];
  public selectedEncoding = "utf-8";
  public currentFile: File | null = null;

  @Watch("selectedFile")
  onSelectedFileChanged(val: File | null) {
    /*Denna funktion anropas flera gånger i samband med val av en fil.
    anledningen skulle kunna vara att InterlabExplorer får sin data via props från
    DefaultView. I den här processen lägger DefaultView ut sina komponenter på nytt
    vilket leder till att FilePick nollställs. Enligt dokumentationen finns vissa
    restriktioner på <Fileinput> som göra att man inte kan sätta ett förvalt
    attribut för File. Det betyder att det blir svårt att låsa denna komponentens
    state (när den återskapas). Vill man att FilePicks state ska hänga ihop med 
    InterlabExplorer är därför enklaste lösningen att bygga i hop dem. Detta vore
    nog rätt logiskt så länge man alltid vill ha en automatisk inläsning av en
    ny fil vid nytt filval. Väljer man att ha en knapp för bekräfta sitt filval
    så blir komponenterna mindre sammankopplade */

    //console.log("selectdFile:", this.selectedFile);
    if (val !== null && val !== undefined) {
      this.currentFile = val;
      this.forwardFile({ file: val, enc: this.selectedEncoding });
    }
    //this.forwardFile();
  }
  @Watch("selectedEncoding")
  onSelectedEncodingChanged(val: string) {
    console.log("encoding changed: ", val, this.currentFile);
    if (this.currentFile !== null) {
      this.forwardFile({ file: this.currentFile, enc: val });
    }
  }

  constructor() {
    super();
  }

  public forwardFile(val: { file: File; enc: string }): void {
    this.$emit("fileevent", val);
  }
}
