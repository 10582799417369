
import ExcelWriter, { IlabToTemplateConfig } from "@/excelWriter";
import { Component, Model, Prop, Vue, Watch } from "vue-property-decorator";
import { Interlab, Row } from "../interlabReader";
import { nextTick } from "vue";
import dvgvconf from "@/assets/dvgv-template-copy.json";
import { BvModal } from "bootstrap-vue";

@Component
export default class InterlabExplorer extends Vue {
  fields: Array<{ key: string; selected: boolean; variant?: string }>;
  //dummy config to be able to point to simpe export
  rawInterlabToTemplateConfig: IlabToTemplateConfig = {
    name: "raw",
    "provadm-conf": { "key-map": [] },
    "provdat-conf": { "key-map": [] },
  };
  selectedConfig = this.rawInterlabToTemplateConfig;

  exportOptions = [
    { text: "Oformaterad export", value: this.rawInterlabToTemplateConfig },
    {
      text: "Datavärdskap grundvattens mall för kemileveranser",
      value: dvgvconf,
    },
  ];

  @Model() private isExporting = false;

  @Prop() public ilab!: Interlab;
  // public visibleProvAdm!: Array<Row>;
  //public visibleProvDat!: Array<Row>;

  constructor() {
    super();
    this.fields = [
      {
        key: "Lablittera",
        selected: true,
      },
      {
        key: "Namn",
        selected: false,
      },
      {
        key: "Ort",
        selected: false,
        variant: "warning",
      },
    ];
    //this.visibleProvAdm = this.ilab.provadm;
    //this.visibleProvDat = this.ilab.provdat;
  }

  public exportExcel() {
    //Beräkningsintiesiv uppgift blockar GUI-aktiviteter. Hjälper inte att utföra som
    // async task (async enbart relevant för i/o operationer och fetch som är non-bocking
    // i sin natur. Lösningen är antingen WebWorkers eller tillåta låsning. CSS animationer
    // blockas ej (körs i separat tråd). Därför ok att blocka GUI om bara vue hinner uppdateras
    // innan processen startas. Ska finnas en hjälpmetod i vue nextTick() för att vänta in
    // GUI uppdatering men den verkar inte fungera som avsett. Hittade följande information
    // som jag implementerade https://stackoverflow.com/questions/74050415/how-to-get-an-svg-spinner-to-show-up-before-a-heavy-ui-update
    this.$bvModal.hide("select-excel-modal");
    /*     this.$bvToast.toast(`Testmeddelande med massor av betydelselös text`, {
      title: "Varningsmeddelande",
      appendToast: true,
      noAutoHide: true,
    }); */
    console.log("set isExporting");
    this.isExporting = true;
    this.nextTwik().then(() => {
      const writer = new ExcelWriter();
      if (this.selectedConfig.name == "raw") {
        writer
          .writeExcel(this.ilab)
          .then(() => setTimeout(() => (this.isExporting = false), 800));
      } else {
        writer
          //  .writeExcel(this.ilab)
          .writeToTemplate(this.ilab, this.selectedConfig)
          .then(() => setTimeout(() => (this.isExporting = false), 800));
      }
    });
  }

  @Watch("ilab", { deep: true })
  ilabChanged() {
    if (this.ilab.provadm.length > 5) {
      //visibleProvAdm()
      //this.visibleProvAdm = this.ilab.provadm.slice(0, 5);
    } else {
      // this.visibleProvAdm = this.ilab.provadm;
    }
    if (this.ilab.provdat.length > 5) {
      //this.visibleProvDat = this.ilab.provdat.slice(0, 5);
    } else {
      //this.visibleProvDat = this.ilab.provdat;
    }
  }

  async nextTwik() {
    return new Promise<void>((resolve) => {
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          requestAnimationFrame(() => {
            requestAnimationFrame(() => {
              resolve();
            });
          });
        });
      });
    });
  }

  public get busy(): boolean {
    return this.isExporting;
  }

  public get visibleProvAdm(): Array<Row> {
    //console.log(this.ilab.provdat[0]["Lablittera"]);
    return this.truncateTable(this.ilab.provadm, 18);
  }

  public get visibleProvDat(): Array<Row> {
    return this.truncateTable(this.ilab.provdat, 18);
  }

  public truncateTable(table: Array<Row>, visibleRows: number): Array<Row> {
    if (this.ilab.provdat.length > visibleRows) {
      let tab: Array<Row> = table.slice(0, visibleRows);
      let extra: Row = {
        Lablittera:
          "...\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0",
      };
      tab.push(extra);
      tab.push(extra);
      return tab;
    } else {
      return table;
    }
  }
}
