import { rejects } from "assert";
import { parse, ParseResult } from "papaparse";
import { resolve } from "path/posix";
import Vue from "vue";
import { VNode } from "vue/types/umd";
export default class InterlabReader {
  private static readonly PROVADM = /(?<=#Provadm\s).*?(?=#Provdat)/gs;
  private static readonly PROVDAT = /(?<=#Provdat\s).*?(?=#Provadm|#Slut)/gs;
  private static readonly VERSION = /(?<=#Version=)\d.\d{1,2}/gs;
  private static readonly DECIMALTECKEN = /(?<=#Decimaltecken=)[^\n\t\d]/gs;
  private static readonly TEXTAVGRANSARE = /(?<=#Textavgränsare=)[^\n]/gs;
  private decimaltecken!: string;
  private version!: string;
  private provadm!: Array<Row>;
  private provdat!: Array<Row>;
  private filename!: string;
  private textavgransare!: string;
  private app: Vue;

  constructor(app: Vue) {
    this.app = app;
  }

  /**
   * Callback when intelab file is parsed
   */
  public onload = () => {
    console.log("on load arrow func");
  };

  public onError = (error: string) => {
    console.log(error);
  };

  /**
   * Read an interlabfile and store as a Javascript object of Type Interlab.
   * Onload callback function is called when this function is finnished.
   *
   * @param interlabfile .lab file to read (as Blob).
   */

  public readAsJson(interlabfile: File, encoding: string): void {
    this.filename = interlabfile.name;
    const reader = new FileReader();
    reader.readAsText(interlabfile, encoding);
    //TODO lyft funktionaliteten i onload till separat funktion - private this.parseInterlab
    //Inte så enkelt som man tror eftersom parseInterlab behöver tillgång till reader.
    //En snyggare lösning vore sannolikt att göra en wrap över FileReader som genererar en
    //Promise och därefter skriva om koden nedan.
    reader.onload = () => {
      const data = reader.result as string;
      this.decimaltecken = InterlabReader.DECIMALTECKEN.exec(
        data
      )?.toString() as string;
      this.version = InterlabReader.VERSION.exec(data)?.toString() as string;
      this.textavgransare = InterlabReader.TEXTAVGRANSARE.exec(
        data
      )?.toString() as string;
      //textavgränsare kan vara Ja eller Nej och skall alltid utgöras av ". Det innebär att
      //papaparse ska kunna hantera det med normalinställningar. Man skulle dock kunna
      //använda fast mode om ingen textavgränsare används.

      this.readSegmentAsJson(InterlabReader.PROVDAT, data)
        .then((val: Array<Row>[]) => {
          try {
            this.provdat = val.reduce((prev: Row[], curr: Row[]) => {
              return prev.concat(curr);
            });
          } catch (error) {
            this.onError(
              "Kunde inte läsa provdat-sekvens, försök med en annan teckenkodning"
            );
          }

          //console.log(val);
          // return next Promise (for provadm)
          return this.readSegmentAsJson(InterlabReader.PROVADM, data);
        })
        .then((val: Array<Row>[]) => {
          try {
            this.provadm = val.reduce((prev: Row[], curr: Row[]) => {
              return prev.concat(curr);
            });
            this.onload();
          } catch (error) {
            this.onError(
              "Kunde inte läsa provadm-sekvens, försök med en annan teckenkodning"
            );
          }
        });
    };
  }

  /**
   * Read provdat or provadm to json segments. Segment readings
   * is stacked in a single Promise
   *
   * @param segment InterlabReader.PROVDAT | InterlabReader.PROVADM
   *
   */
  private readSegmentAsJson(
    segment: RegExp,
    data: string
  ): Promise<Array<Array<Row>>> {
    console.log("cuote char ", '"');
    const promises = new Array<Promise<Array<Row>>>();
    let seg: RegExpExecArray | null;
    while ((seg = segment.exec(data)) != null) {
      const myPromise = new Promise<Array<Row>>((resolve) => {
        const papaparsecallback = (
          res: ParseResult<{
            data: Array<Row>;
            error: Array<any>;
            meta: any;
          }>
        ): void => {
          if (res.meta.aborted == true) {
            this.app.$bvToast.toast(`Den valda filen kunde inte tolkas`, {
              title: "Fel",
              appendToast: true,
              noAutoHide: true,
            });
            throw new Error("CSV parse error");
          }
          //console.log(res.errors);
          if (res.errors.length > 0) {
            const msg: VNode = this.app.$createElement(
              "pre",
              {
                class: ["text-left", "mb-0"],
              },
              [
                "Tolkningsproblem uppstod vid inläsning av ett csv segment:\n",
                JSON.stringify(res.errors, null, 2),
              ]
            );
            this.app.$bvToast.toast(msg, {
              title: "Varning",
              appendToast: true,
              noAutoHide: true,
              bodyClass: ".b-toaster:800px",
            });
            console.log("csv segment parse errors: ", res.errors);
          } else {
            console.log("csv segment parsed without errors");
          }
          resolve(res.data as unknown as Array<Row>);
        };
        if (seg != null) {
          parse(seg.toString().trim(), {
            header: true,
            delimiter: ";",
            quoteChar: '"',
            fastMode: false,
            complete: papaparsecallback,
          });
        }
      });
      promises.push(myPromise);
    }
    return Promise.all(promises);
  }

  private getProvadm(): Array<Row> {
    //console.log("getProvadm called");
    return this.provadm;
  }

  private getProvdat(): Array<Row> {
    return this.provdat;
  }

  private getDecimaltecken(): string {
    return this.decimaltecken;
  }

  private getVersion(): string {
    return this.version;
  }

  public getInterlab(): Interlab {
    return {
      version: this.getVersion(),
      decimaltecken: this.getDecimaltecken(),
      provadm: this.getProvadm(),
      provdat: this.getProvdat(),
      filename: this.filename,
    };
  }
}

export type Row = Record<string, string>;

export interface ProvAdmRow {
  Lablittera: string;
  Namn: string;
  Adress: string;
  Postnr: string;
  Ort: string;
  Kommunkod: string;
  Projekt: string;
  Laboratorium: string;
  Provtyp: string;
  Provtagare: string;
  Registertyp: string;
  ProvplatsID: string;
  Provplatsnamn: string;
  "Specifik provplats": string;
  Provtagningsorsak: string;
  Provtypspecifikation: string;
  Bedömning: string;
  "Kemisk bedömning": string;
  "Mikrobiologisk bedömning": string;
  Kommentar: string;
  År: string;
  Provtagningsdatum: string;
  Provtagningstid: string;
  Inlämningsdatum: string;
  Inlämningstid: string;
}

export interface Interlab {
  version?: string;
  decimaltecken?: string;
  filename?: string;
  provadm: Array<Row>;
  provdat: Array<Row>;
}
