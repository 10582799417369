import { ProvAdmRow, Row, Interlab } from "./interlabReader";
import * as Excel from "exceljs";
import { FormRadioPlugin } from "bootstrap-vue";
import * as fs from "file-saver";
import { resolve } from "path/posix";
import { Stream } from "stream";
//import dvgv from "@/assets/dvgv-template.json";

export default class ExcelWriter {
  private workbook;
  private provadmsheet;
  private provdatsheet;
  constructor() {
    this.workbook = new Excel.Workbook();
    this.workbook.creator = "Vattensmedjan";
    this.provadmsheet = this.workbook.addWorksheet("provadm");
    this.provdatsheet = this.workbook.addWorksheet("provdat");
  }

  public onReady = () => {
    console.log("on ready arrow func");
  };

  /* public writeInterlab(data: Interlab): void {
    //const row: ProvAdmRow = data.provadm[0] as unknown as ProvAdmRow;
    const provdatarr: Array<Array<any>> = [];
    const casted: Array<Array<Record<string, string>>> = provdatarr as Array<
      Array<Record<string, string>>
    >;
    // casted.forEach((row) => {
    //   row.forEach((rec) => {
    //     rec[1].replace(",", ".");
    //   });
    // });

    data.provdat.forEach((val) => {
      const typedRow: Record<string, string | number> =
        this.createTypedExcelRow(val) as Record<string, string | number>;
      provdatarr.push(Object.values(typedRow));
    });
    //casted.forEach((val) => provdatarr.push(Object.values(val)));

    const provadmarr: Array<Array<any>> = [];
    data.provadm.forEach((val) => {
      provadmarr.push(Object.values(val));
    });

    //problem med att skriva key values direkt till exceljs. Oklart varför
    const rows = [
      Object.values({ id: 6, name: "Barbara", dob: new Date() }),
      Object.values({ id: 2, name: "Sven", dob: new Date() }),
      Object.values({ id: 7, name: "Göran", dob: new Date() }),
    ];
    this.provdatsheet.addRows(provdatarr);
    this.provdatsheet.insertRow(1, Object.keys(data.provdat[0]));
    this.provadmsheet.addRows(provadmarr);
    this.provadmsheet.insertRow(1, Object.keys(data.provadm[0]));
    //console.log("workbook: ", this.workbook);
    const buff = this.workbook.xlsx.writeBuffer().then((data: Excel.Buffer) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(blob, "export.xlsx");
      console.log("calling onready");
      this.onReady();
    });
  } */

  public async writeExcel(data: Interlab): Promise<void> {
    const task1 = this.prepareWrite(data);
    await task1;
    const buff = await this.workbook.xlsx.writeBuffer();
    const blob = new Blob([buff], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    fs.saveAs(blob, "export.xlsx");
    console.log("calling onready");
    this.onReady();

    // task1.then(() => {
    //   const buff = this.workbook.xlsx
    //     .writeBuffer()
    //     .then((data: Excel.Buffer) => {
    //       const blob = new Blob([data], {
    //         type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    //       });
    //       fs.saveAs(blob, "export.xlsx");
    //       console.log("calling onready");
    //       this.onReady();
    //     });
    // });
  }

  public async writeToTemplate(
    data: Interlab,
    conf: IlabToTemplateConfig
  ): Promise<void> {
    const req = new XMLHttpRequest();
    req.open("GET", "dvgv_template.xlsx", true);
    req.responseType = "arraybuffer";
    req.onload = (event) => {
      const buff = req.response;
      console.log("the buffer", buff);
      this.workbook.xlsx.load(buff).then((xlsTemplate) => {
        /*console.log("the workbook", xlsTemplate);
        xlsTemplate.getWorksheet(1).getRow(1).getCell(5).value = "addedvalue";
        xlsTemplate
          .getWorksheet(dvgv.ilab.provadm.Inlämningsdatum["template-sheet"])
          .getRow(dvgv.ilab.provadm.Inlämningsdatum["template-first-row"])
          .getCell(dvgv.ilab.provadm.Inlämningsdatum["template-column"]).value =
          "more values";
         conf["provadm-conf"]["key-map"].forEach((row) => {
          console.log("new config file");
          console.log(row, row["key"]);
          const k = row.key;
          const dataRow = data.provadm[0];
          console.log(k, dataRow[k]);
        }); */
        /* const provadmarr: Array<Array<any>> = [];
        const v = data?.provadm?.shift();
        const pa = dvgv.ilab.provadm;
        const keys = Object.keys(pa);
        keys.forEach((k) => {
          console.log(
            k,
            v ? v[k] : undefined,
            pa[k as keyof typeof pa]["template-column"],
            pa[k as keyof typeof pa]["template-firs-trow"],
            pa[k as keyof typeof pa]["template-sheet"]
          );
        });

        console.log("mapToTemplate", data, conf, xlsTemplate);
         */
        this.mapToTemplate(data, conf, xlsTemplate);

        const buff2 = xlsTemplate.xlsx.writeBuffer().then((b) => {
          const blob = new Blob([b], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          fs.saveAs(blob, "export.xlsx");
          console.log("calling onready");
          this.onReady();
        });
      });
      //this.workbook.xlsx.readFile(blob).then((val) => {
      //  console.log(val);
      //});
      // ...
    };
    req.send();

    //await this.workbook.xlsx.readFile(dvgv);
    /* console.log(this.workbook);
    const buff = await this.workbook.xlsx.writeBuffer();
    const blob = new Blob([buff], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    fs.saveAs(blob, "export.xlsx");
    console.log("calling onready"); */
    this.onReady();
    /* this.workbook.xlsx.readFile("./public/dvgv.xlsx").then(function() {
      var worksheet = this.workbook.getWorksheet(1);
      var row = worksheet.getRow(5);
      row.getCell(1).value = 5; // A5's value set to 5
      row.commit();
      return workbook.xlsx.writeFile('new.xlsx');
  })) */
  }

  /**Maps interlab data to desired excelTemplate using an Interlab-To-Template configuration
   *
   * @param data
   * @param mapConfig
   * @param xlsTemplate
   */
  public mapToTemplate(
    data: Interlab,
    mapConfig: IlabToTemplateConfig,
    xlsTemplate: Excel.Workbook
  ): void {
    const provAdmTable = data.provadm;
    const provDatTable = data.provdat;
    for (let index = 0; index < provAdmTable.length; index++) {
      this.mapRowToTemplate(
        provAdmTable[index],
        index,
        mapConfig["provadm-conf"]["key-map"],
        xlsTemplate
      );
    }
    for (let index = 0; index < provDatTable.length; index++) {
      const row = this.createTypedExcelRow(provDatTable[index]);
      this.mapRowToTemplate(
        row,
        index,
        mapConfig["provdat-conf"]["key-map"],
        xlsTemplate
      );
    }
  }

  /**Takes a data row and map that row to correct fields in the excel template
   *
   * @param row an interlab data row
   * @param rowIndex index of target row
   * @param config map configuration for excel template
   * @param xlsTemplate excel template to fill
   */

  private mapRowToTemplate(
    row: Record<string, string | number>,
    rowIndex: number,
    conf: Array<MapConfig>,
    xlsTemplate: Excel.Workbook
  ) {
    conf.forEach((val) => {
      const currRow = val["template-first-row"] + rowIndex;
      xlsTemplate
        .getWorksheet(val["template-sheet"])
        .getRow(currRow)
        .getCell(val["template-column"]).value = row[val.key];
    });
  }

  public prepareWrite(data: Interlab): Promise<void> {
    const prepPromise: Promise<void> = new Promise((resolve, reject) => {
      const provdatarr: Array<Array<any>> = [];
      const casted: Array<Array<Record<string, string>>> = provdatarr as Array<
        Array<Record<string, string>>
      >;
      // casted.forEach((row) => {
      //   row.forEach((rec) => {
      //     rec[1].replace(",", ".");
      //   });
      // });

      data.provdat.forEach((val) => {
        const typedRow: Record<string, string | number> =
          this.createTypedExcelRow(val) as Record<string, string | number>;
        provdatarr.push(Object.values(typedRow));
      });
      //casted.forEach((val) => provdatarr.push(Object.values(val)));

      const provadmarr: Array<Array<any>> = [];
      data.provadm.forEach((val) => {
        provadmarr.push(Object.values(val));
      });
      this.provdatsheet.addRows(provdatarr);
      this.provdatsheet.insertRow(1, Object.keys(data.provdat[0]));
      this.provadmsheet.addRows(provadmarr);
      this.provadmsheet.insertRow(1, Object.keys(data.provadm[0]));
      console.log("ready preparing");

      resolve();
    });
    return prepPromise;
  }

  //   private writerTask = new Promise<void>((resolve, reject) => {
  //     const provdatarr: Array<Array<any>> = [];

  //     data.provdat.forEach((val) => {
  //       const typedRow: Record<string, string | number> =
  //         this.createTypedExcelRow(val) as Record<string, string | number>;
  //       provdatarr.push(Object.values(typedRow));
  //     });
  //     //casted.forEach((val) => provdatarr.push(Object.values(val)));

  //     const provadmarr: Array<Array<any>> = [];
  //     data.provadm.forEach((val) => {
  //       provadmarr.push(Object.values(val));
  //     });

  //     this.provdatsheet.addRows(provdatarr);
  //     this.provdatsheet.insertRow(1, Object.keys(data.provdat[0]));
  //     this.provadmsheet.addRows(provadmarr);
  //     this.provadmsheet.insertRow(1, Object.keys(data.provadm[0]));

  //     const buff = this.workbook.xlsx.writeBuffer().then((data: Excel.Buffer) => {
  //       const blob = new Blob([data], {
  //         type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //       });
  //       fs.saveAs(blob, "export.xlsx");
  //       console.log("calling onready");
  //       this.onReady();
  //     });
  //   });

  /**
   * Takes an interlab row and converts Mätvärdetal, Rapporteringsgräns och Detektionsgräns till numeriska värden
   * @param val the row to handle
   * @returns same row but with numeric values for Mätvärdetal, Rapporteringsgräns och Detektionsgräns
   */
  private createTypedExcelRow(val: Row): Record<string, string | number> {
    const typedRow: Record<string, string | number> = JSON.parse(
      JSON.stringify(val)
    ) as Record<string, string | number>;
    typedRow["Mätvärdetal"] = this.convertToNumber(
      typedRow["Mätvärdetal"] as string
    );
    typedRow["Rapporteringsgräns"] = this.convertToNumber(
      typedRow["Rapporteringsgräns"] as string
    );
    typedRow["Detektionsgräns"] = this.convertToNumber(
      typedRow["Detektioinsgräns"] as string
    );
    return typedRow;
  }

  private convertToNumber(val: string): string | number {
    if (val === "" || val === undefined) {
      //do nothing
    } else {
      try {
        return Number.parseFloat(val.replace(",", "."));
      } catch (error) {
        //console.log("unable to convert ", val, " to number");
      }
    }
    return val;
  }
}

export interface MapConfig {
  key: string;
  "template-sheet": number;
  "template-column": number;
  "template-first-row": number;
}

export interface IlabToTemplateConfig {
  name?: string;
  "provadm-conf": {
    "horisontal-directon"?: boolean;
    "key-map": Array<MapConfig>;
  };
  "provdat-conf": {
    "horisontal-directon"?: boolean;
    "key-map": Array<MapConfig>;
  };
}
